
import {
  Component,
  Vue,
} from 'nuxt-property-decorator'

@Component
export default class Header extends Vue {

  previousScrollY: number = 0

  handleIsSticky() {
    this.$store.commit('setIsSticky', (window.scrollY > (window.innerHeight / 2)) && (this.previousScrollY > window.scrollY))
    this.previousScrollY = window.scrollY
  }

  mounted() {
    window.addEventListener('scroll', this.handleIsSticky)
  }
}
