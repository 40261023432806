import Vue from 'vue'

const hashChecker = {
  install() {
    Vue.prototype.$hashChecker = (hash) => {
      if (hash === window.location.hash) {
        const el = document.querySelector(hash)
        window.scrollTo({ top: el.offsetTop - 25, behavior: 'smooth' })
      }
    }
  },
}

Vue.use(hashChecker)
