export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const NuxtDynamic = () => import('../../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => wrapFunctional(c.default || c))
export const ArticleList = () => import('../../components/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => wrapFunctional(c.default || c))
export const CategoryList = () => import('../../components/CategoryList.vue' /* webpackChunkName: "components/category-list" */).then(c => wrapFunctional(c.default || c))
export const Contact = () => import('../../components/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const Decor = () => import('../../components/Decor.vue' /* webpackChunkName: "components/decor" */).then(c => wrapFunctional(c.default || c))
export const DynamicBlocksRenderer = () => import('../../components/DynamicBlocksRenderer.vue' /* webpackChunkName: "components/dynamic-blocks-renderer" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Headline = () => import('../../components/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c))
export const HeadlineLabel = () => import('../../components/HeadlineLabel.vue' /* webpackChunkName: "components/headline-label" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Polaroid = () => import('../../components/Polaroid.vue' /* webpackChunkName: "components/polaroid" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ScrollToTop = () => import('../../components/ScrollToTop.vue' /* webpackChunkName: "components/scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const ThemeSwitch = () => import('../../components/ThemeSwitch.vue' /* webpackChunkName: "components/theme-switch" */).then(c => wrapFunctional(c.default || c))
export const BlocksBanner = () => import('../../components/blocks/Banner.vue' /* webpackChunkName: "components/blocks-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksDivider = () => import('../../components/blocks/Divider.vue' /* webpackChunkName: "components/blocks-divider" */).then(c => wrapFunctional(c.default || c))
export const BlocksHero = () => import('../../components/blocks/Hero.vue' /* webpackChunkName: "components/blocks-hero" */).then(c => wrapFunctional(c.default || c))
export const BlocksKudoGenerator = () => import('../../components/blocks/KudoGenerator.vue' /* webpackChunkName: "components/blocks-kudo-generator" */).then(c => wrapFunctional(c.default || c))
export const BlocksLatestArticles = () => import('../../components/blocks/LatestArticles.vue' /* webpackChunkName: "components/blocks-latest-articles" */).then(c => wrapFunctional(c.default || c))
export const BlocksQuestionable = () => import('../../components/blocks/Questionable.vue' /* webpackChunkName: "components/blocks-questionable" */).then(c => wrapFunctional(c.default || c))
export const BlocksQuote = () => import('../../components/blocks/Quote.vue' /* webpackChunkName: "components/blocks-quote" */).then(c => wrapFunctional(c.default || c))
export const BlocksRelatedArticles = () => import('../../components/blocks/RelatedArticles.vue' /* webpackChunkName: "components/blocks-related-articles" */).then(c => wrapFunctional(c.default || c))
export const BlocksSection = () => import('../../components/blocks/Section.vue' /* webpackChunkName: "components/blocks-section" */).then(c => wrapFunctional(c.default || c))
export const BlocksShare = () => import('../../components/blocks/Share.vue' /* webpackChunkName: "components/blocks-share" */).then(c => wrapFunctional(c.default || c))
export const BlocksTextWithPolaroids = () => import('../../components/blocks/TextWithPolaroids.vue' /* webpackChunkName: "components/blocks-text-with-polaroids" */).then(c => wrapFunctional(c.default || c))
export const BlocksTwoColumns = () => import('../../components/blocks/TwoColumns.vue' /* webpackChunkName: "components/blocks-two-columns" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorBackground = () => import('../../components/decors/DecorBackground.vue' /* webpackChunkName: "components/decors-decor-background" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorBase = () => import('../../components/decors/DecorBase.vue' /* webpackChunkName: "components/decors-decor-base" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorDots1 = () => import('../../components/decors/DecorDots1.vue' /* webpackChunkName: "components/decors-decor-dots1" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorLine1 = () => import('../../components/decors/DecorLine1.vue' /* webpackChunkName: "components/decors-decor-line1" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorLines1 = () => import('../../components/decors/DecorLines1.vue' /* webpackChunkName: "components/decors-decor-lines1" */).then(c => wrapFunctional(c.default || c))
export const DecorsDecorShape1 = () => import('../../components/decors/DecorShape1.vue' /* webpackChunkName: "components/decors-decor-shape1" */).then(c => wrapFunctional(c.default || c))
export const ErrorActions = () => import('../../components/error/Actions.vue' /* webpackChunkName: "components/error-actions" */).then(c => wrapFunctional(c.default || c))
export const ErrorCardGenerationError = () => import('../../components/error/CardGenerationError.vue' /* webpackChunkName: "components/error-card-generation-error" */).then(c => wrapFunctional(c.default || c))
export const ErrorCardNotFound = () => import('../../components/error/CardNotFound.vue' /* webpackChunkName: "components/error-card-not-found" */).then(c => wrapFunctional(c.default || c))
export const ErrorContainer = () => import('../../components/error/Container.vue' /* webpackChunkName: "components/error-container" */).then(c => wrapFunctional(c.default || c))
export const ErrorGeneric = () => import('../../components/error/Generic.vue' /* webpackChunkName: "components/error-generic" */).then(c => wrapFunctional(c.default || c))
export const ErrorMessage = () => import('../../components/error/Message.vue' /* webpackChunkName: "components/error-message" */).then(c => wrapFunctional(c.default || c))
export const ErrorStatusCode = () => import('../../components/error/StatusCode.vue' /* webpackChunkName: "components/error-status-code" */).then(c => wrapFunctional(c.default || c))
export const IconsIconArrowUp = () => import('../../components/icons/IconArrowUp.vue' /* webpackChunkName: "components/icons-icon-arrow-up" */).then(c => wrapFunctional(c.default || c))
export const IconsIconAtSymbol = () => import('../../components/icons/IconAtSymbol.vue' /* webpackChunkName: "components/icons-icon-at-symbol" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBadgeCheck = () => import('../../components/icons/IconBadgeCheck.vue' /* webpackChunkName: "components/icons-icon-badge-check" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBase = () => import('../../components/icons/IconBase.vue' /* webpackChunkName: "components/icons-icon-base" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBook = () => import('../../components/icons/IconBook.vue' /* webpackChunkName: "components/icons-icon-book" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCalendar = () => import('../../components/icons/IconCalendar.vue' /* webpackChunkName: "components/icons-icon-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconsIconClose = () => import('../../components/icons/IconClose.vue' /* webpackChunkName: "components/icons-icon-close" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDarkMode = () => import('../../components/icons/IconDarkMode.vue' /* webpackChunkName: "components/icons-icon-dark-mode" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDivider = () => import('../../components/icons/IconDivider.vue' /* webpackChunkName: "components/icons-icon-divider" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFacebook = () => import('../../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icons-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFire = () => import('../../components/icons/IconFire.vue' /* webpackChunkName: "components/icons-icon-fire" */).then(c => wrapFunctional(c.default || c))
export const IconsIconHappyRacoon = () => import('../../components/icons/IconHappyRacoon.vue' /* webpackChunkName: "components/icons-icon-happy-racoon" */).then(c => wrapFunctional(c.default || c))
export const IconsIconHeart = () => import('../../components/icons/IconHeart.vue' /* webpackChunkName: "components/icons-icon-heart" */).then(c => wrapFunctional(c.default || c))
export const IconsIconHome = () => import('../../components/icons/IconHome.vue' /* webpackChunkName: "components/icons-icon-home" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLightMode = () => import('../../components/icons/IconLightMode.vue' /* webpackChunkName: "components/icons-icon-light-mode" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLinkedIn = () => import('../../components/icons/IconLinkedIn.vue' /* webpackChunkName: "components/icons-icon-linked-in" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLogo = () => import('../../components/icons/IconLogo.vue' /* webpackChunkName: "components/icons-icon-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLotus = () => import('../../components/icons/IconLotus.vue' /* webpackChunkName: "components/icons-icon-lotus" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMail = () => import('../../components/icons/IconMail.vue' /* webpackChunkName: "components/icons-icon-mail" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMenu = () => import('../../components/icons/IconMenu.vue' /* webpackChunkName: "components/icons-icon-menu" */).then(c => wrapFunctional(c.default || c))
export const IconsIconNavInstagram = () => import('../../components/icons/IconNavInstagram.vue' /* webpackChunkName: "components/icons-icon-nav-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsIconNavLinkedIn = () => import('../../components/icons/IconNavLinkedIn.vue' /* webpackChunkName: "components/icons-icon-nav-linked-in" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPhone = () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icons-icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsIconQuestionMarkCircle = () => import('../../components/icons/IconQuestionMarkCircle.vue' /* webpackChunkName: "components/icons-icon-question-mark-circle" */).then(c => wrapFunctional(c.default || c))
export const IconsIconRacoonWithShield = () => import('../../components/icons/IconRacoonWithShield.vue' /* webpackChunkName: "components/icons-icon-racoon-with-shield" */).then(c => wrapFunctional(c.default || c))
export const IconsIconSadRacoon = () => import('../../components/icons/IconSadRacoon.vue' /* webpackChunkName: "components/icons-icon-sad-racoon" */).then(c => wrapFunctional(c.default || c))
export const IconsIconScale = () => import('../../components/icons/IconScale.vue' /* webpackChunkName: "components/icons-icon-scale" */).then(c => wrapFunctional(c.default || c))
export const IconsIconShare = () => import('../../components/icons/IconShare.vue' /* webpackChunkName: "components/icons-icon-share" */).then(c => wrapFunctional(c.default || c))
export const IconsIconShieldExclamation = () => import('../../components/icons/IconShieldExclamation.vue' /* webpackChunkName: "components/icons-icon-shield-exclamation" */).then(c => wrapFunctional(c.default || c))
export const IconsIconTwitter = () => import('../../components/icons/IconTwitter.vue' /* webpackChunkName: "components/icons-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsIconVegan = () => import('../../components/icons/IconVegan.vue' /* webpackChunkName: "components/icons-icon-vegan" */).then(c => wrapFunctional(c.default || c))
export const IconsIconWhatsApp = () => import('../../components/icons/IconWhatsApp.vue' /* webpackChunkName: "components/icons-icon-whats-app" */).then(c => wrapFunctional(c.default || c))
export const IconsIconXing = () => import('../../components/icons/IconXing.vue' /* webpackChunkName: "components/icons-icon-xing" */).then(c => wrapFunctional(c.default || c))
export const KudoCard = () => import('../../components/kudo/Card.vue' /* webpackChunkName: "components/kudo-card" */).then(c => wrapFunctional(c.default || c))
export const KudoCardContainer = () => import('../../components/kudo/CardContainer.vue' /* webpackChunkName: "components/kudo-card-container" */).then(c => wrapFunctional(c.default || c))
export const KudoImage = () => import('../../components/kudo/Image.vue' /* webpackChunkName: "components/kudo-image" */).then(c => wrapFunctional(c.default || c))
export const KudoText = () => import('../../components/kudo/Text.vue' /* webpackChunkName: "components/kudo-text" */).then(c => wrapFunctional(c.default || c))
export const KudoTextarea = () => import('../../components/kudo/Textarea.vue' /* webpackChunkName: "components/kudo-textarea" */).then(c => wrapFunctional(c.default || c))
export const KudoTitle = () => import('../../components/kudo/Title.vue' /* webpackChunkName: "components/kudo-title" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDefault = () => import('../../components/layouts/Default.vue' /* webpackChunkName: "components/layouts-default" */).then(c => wrapFunctional(c.default || c))
export const LayoutsWithoutIntro = () => import('../../components/layouts/WithoutIntro.vue' /* webpackChunkName: "components/layouts-without-intro" */).then(c => wrapFunctional(c.default || c))
export const MenuInternalLink = () => import('../../components/menu/InternalLink.vue' /* webpackChunkName: "components/menu-internal-link" */).then(c => wrapFunctional(c.default || c))
export const MenuPage = () => import('../../components/menu/Page.vue' /* webpackChunkName: "components/menu-page" */).then(c => wrapFunctional(c.default || c))
export const QuestionableAnswerCard = () => import('../../components/questionable/AnswerCard.vue' /* webpackChunkName: "components/questionable-answer-card" */).then(c => wrapFunctional(c.default || c))
export const QuestionableCardDeck = () => import('../../components/questionable/CardDeck.vue' /* webpackChunkName: "components/questionable-card-deck" */).then(c => wrapFunctional(c.default || c))
export const QuestionableProgress = () => import('../../components/questionable/Progress.vue' /* webpackChunkName: "components/questionable-progress" */).then(c => wrapFunctional(c.default || c))
export const QuestionableQuestionCard = () => import('../../components/questionable/QuestionCard.vue' /* webpackChunkName: "components/questionable-question-card" */).then(c => wrapFunctional(c.default || c))
export const SharedButton = () => import('../../components/shared/Button.vue' /* webpackChunkName: "components/shared-button" */).then(c => wrapFunctional(c.default || c))
export const SharedDivider = () => import('../../components/shared/Divider.vue' /* webpackChunkName: "components/shared-divider" */).then(c => wrapFunctional(c.default || c))
export const SharedHeader = () => import('../../components/shared/Header.vue' /* webpackChunkName: "components/shared-header" */).then(c => wrapFunctional(c.default || c))
export const SharedRichContent = () => import('../../components/shared/RichContent.vue' /* webpackChunkName: "components/shared-rich-content" */).then(c => wrapFunctional(c.default || c))
export const KudoLayoutsHighfive = () => import('../../components/kudo/layouts/Highfive.vue' /* webpackChunkName: "components/kudo-layouts-highfive" */).then(c => wrapFunctional(c.default || c))
export const KudoLayoutsSuperstar = () => import('../../components/kudo/layouts/Superstar.vue' /* webpackChunkName: "components/kudo-layouts-superstar" */).then(c => wrapFunctional(c.default || c))
export const KudoLayoutsThanks = () => import('../../components/kudo/layouts/Thanks.vue' /* webpackChunkName: "components/kudo-layouts-thanks" */).then(c => wrapFunctional(c.default || c))
export const KudoLayoutsThanksChristmas = () => import('../../components/kudo/layouts/ThanksChristmas.vue' /* webpackChunkName: "components/kudo-layouts-thanks-christmas" */).then(c => wrapFunctional(c.default || c))
export const KudoLayoutsWow = () => import('../../components/kudo/layouts/Wow.vue' /* webpackChunkName: "components/kudo-layouts-wow" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
