import Vue from 'vue'
import { v4 } from 'uuid'


const uuidv4 = {
  install() {
    Vue.prototype.$uuidv4 = () => {
      return v4()
    }
  },
}

Vue.use(uuidv4)
