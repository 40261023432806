import Vue from 'vue'

const prepareContactForm = {
  install(Vue, store) {
    Vue.prototype.$prepareContactForm = (subject) => {
      const emailInputField = document.querySelector('#contactFormEmail')
      emailInputField.select()
      store.commit('setContactFormSubject', subject)
    }
  },
}

export default ({ app: { store } }) => {
  Vue.use(prepareContactForm, store)
}
