import * as klaro from 'klaro/dist/klaro-no-css'

const klaroConfig = {
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN,
  lang: 'de',
  htmlTexts: true,
  acceptAll: true,
  styling: {
    theme: ['light', 'wide'],
  },
  translations: {
    de: {
      consentModal: {
        title: 'Cookie-Einstellungen',
        description:
          'Hier kannst Du die Dienste, die Du auf meiner Webseite nutzen möchtest, anpassen. Du hast das Sagen! Aktiviere oder deaktiviere die Dienste, wie Du es für richtig hälst. Weitere Informationen findest Du in meiner <a href="/datenschutz/">Datenschutzerklärung</a>.',
      },
      consentNotice: {
        description:
          'Hi, könnte ich bitte einige zusätzliche Dienste für {purposes} aktivieren? Du kannst Deine Zustimmung jederzeit ändern oder zurückziehen.',
        learnMore: 'Mehr erfahren',
      },
      'google-analytics': {
        title: 'Google Analytics',
        description:
          'Google Analytics ist ein Trackingtool des US-amerikanischen Unternehmens Google LLC, das der Datenverkehrsanalyse von Webseiten (Webanalyse) dient.',
      },
      'google-tag-manager': {
        title: 'Google Tag Manager',
        description:
          'Der Google Tag Manager erleichtert die Implementierung von Webanalyse Tools wie Google Analytics auf Webseiten.',
      },
      purposes: {
        marketing: 'Marketing',
        functional: 'Funktional',
      },
    },
  },
  services: [
    {
      name: 'google-tag-manager',
      purposes: ['marketing'],
      onAccept: `
          for(let k of Object.keys(opts.consents)){
              if (opts.consents[k]){
                  let eventName = 'klaro-'+k+'-accepted'
                  dataLayer.push({'event': eventName})
              }
          }
          if (opts.consents[opts.vars.googleAnalyticsName || 'google-analytics']){
              gtag('consent', 'update', {'analytics_storage': 'granted'})
          }
          if (opts.consents[opts.vars.adStorageName || 'google-ads']){
              gtag('consent', 'update', {'ad_storage': 'granted'})
          }
      `,
      onInit: `
          window.dataLayer = window.dataLayer || [];
          window.gtag = function(){dataLayer.push(arguments)}
          gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
          gtag('set', 'ads_data_redaction', true)
      `,
      onDecline: `
          window.dataLayer = window.dataLayer || [];
          window.gtag = function(){dataLayer.push(arguments)}
          gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
          gtag('set', 'ads_data_redaction', true)
      `,
      vars: {
        googleAnalytics: 'google-analytics',
      },
    },
    {
      name: 'google-analytics',
      purposes: ['marketing'],
      cookies: [/^_ga(_.*)?/, '_gid', /^_dc_gtm.*$/i],
    },
  ],
}

window.klaro = klaro
window.klaroConfig = klaroConfig
klaro.setup(klaroConfig)
