export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyDelayHydration: () => import('../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => c.default || c),
      LazyHydrationStatus: () => import('../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => c.default || c),
      LazyNuxtDynamic: () => import('../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyArticleList: () => import('../components/ArticleList.vue' /* webpackChunkName: "components/article-list" */).then(c => c.default || c),
      LazyCategoryList: () => import('../components/CategoryList.vue' /* webpackChunkName: "components/category-list" */).then(c => c.default || c),
      LazyContact: () => import('../components/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => c.default || c),
      LazyContainer: () => import('../components/Container.vue' /* webpackChunkName: "components/container" */).then(c => c.default || c),
      LazyDecor: () => import('../components/Decor.vue' /* webpackChunkName: "components/decor" */).then(c => c.default || c),
      LazyDynamicBlocksRenderer: () => import('../components/DynamicBlocksRenderer.vue' /* webpackChunkName: "components/dynamic-blocks-renderer" */).then(c => c.default || c),
      LazyFooter: () => import('../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => c.default || c),
      LazyHeader: () => import('../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => c.default || c),
      LazyHeadline: () => import('../components/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => c.default || c),
      LazyHeadlineLabel: () => import('../components/HeadlineLabel.vue' /* webpackChunkName: "components/headline-label" */).then(c => c.default || c),
      LazyIcon: () => import('../components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => c.default || c),
      LazyLoadingBar: () => import('../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => c.default || c),
      LazyMenu: () => import('../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => c.default || c),
      LazyPolaroid: () => import('../components/Polaroid.vue' /* webpackChunkName: "components/polaroid" */).then(c => c.default || c),
      LazyProgressBar: () => import('../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => c.default || c),
      LazyScrollToTop: () => import('../components/ScrollToTop.vue' /* webpackChunkName: "components/scroll-to-top" */).then(c => c.default || c),
      LazyThemeSwitch: () => import('../components/ThemeSwitch.vue' /* webpackChunkName: "components/theme-switch" */).then(c => c.default || c),
      LazyBlocksBanner: () => import('../components/blocks/Banner.vue' /* webpackChunkName: "components/blocks-banner" */).then(c => c.default || c),
      LazyBlocksDivider: () => import('../components/blocks/Divider.vue' /* webpackChunkName: "components/blocks-divider" */).then(c => c.default || c),
      LazyBlocksHero: () => import('../components/blocks/Hero.vue' /* webpackChunkName: "components/blocks-hero" */).then(c => c.default || c),
      LazyBlocksKudoGenerator: () => import('../components/blocks/KudoGenerator.vue' /* webpackChunkName: "components/blocks-kudo-generator" */).then(c => c.default || c),
      LazyBlocksLatestArticles: () => import('../components/blocks/LatestArticles.vue' /* webpackChunkName: "components/blocks-latest-articles" */).then(c => c.default || c),
      LazyBlocksQuestionable: () => import('../components/blocks/Questionable.vue' /* webpackChunkName: "components/blocks-questionable" */).then(c => c.default || c),
      LazyBlocksQuote: () => import('../components/blocks/Quote.vue' /* webpackChunkName: "components/blocks-quote" */).then(c => c.default || c),
      LazyBlocksRelatedArticles: () => import('../components/blocks/RelatedArticles.vue' /* webpackChunkName: "components/blocks-related-articles" */).then(c => c.default || c),
      LazyBlocksSection: () => import('../components/blocks/Section.vue' /* webpackChunkName: "components/blocks-section" */).then(c => c.default || c),
      LazyBlocksShare: () => import('../components/blocks/Share.vue' /* webpackChunkName: "components/blocks-share" */).then(c => c.default || c),
      LazyBlocksTextWithPolaroids: () => import('../components/blocks/TextWithPolaroids.vue' /* webpackChunkName: "components/blocks-text-with-polaroids" */).then(c => c.default || c),
      LazyBlocksTwoColumns: () => import('../components/blocks/TwoColumns.vue' /* webpackChunkName: "components/blocks-two-columns" */).then(c => c.default || c),
      LazyDecorsDecorBackground: () => import('../components/decors/DecorBackground.vue' /* webpackChunkName: "components/decors-decor-background" */).then(c => c.default || c),
      LazyDecorsDecorBase: () => import('../components/decors/DecorBase.vue' /* webpackChunkName: "components/decors-decor-base" */).then(c => c.default || c),
      LazyDecorsDecorDots1: () => import('../components/decors/DecorDots1.vue' /* webpackChunkName: "components/decors-decor-dots1" */).then(c => c.default || c),
      LazyDecorsDecorLine1: () => import('../components/decors/DecorLine1.vue' /* webpackChunkName: "components/decors-decor-line1" */).then(c => c.default || c),
      LazyDecorsDecorLines1: () => import('../components/decors/DecorLines1.vue' /* webpackChunkName: "components/decors-decor-lines1" */).then(c => c.default || c),
      LazyDecorsDecorShape1: () => import('../components/decors/DecorShape1.vue' /* webpackChunkName: "components/decors-decor-shape1" */).then(c => c.default || c),
      LazyErrorActions: () => import('../components/error/Actions.vue' /* webpackChunkName: "components/error-actions" */).then(c => c.default || c),
      LazyErrorCardGenerationError: () => import('../components/error/CardGenerationError.vue' /* webpackChunkName: "components/error-card-generation-error" */).then(c => c.default || c),
      LazyErrorCardNotFound: () => import('../components/error/CardNotFound.vue' /* webpackChunkName: "components/error-card-not-found" */).then(c => c.default || c),
      LazyErrorContainer: () => import('../components/error/Container.vue' /* webpackChunkName: "components/error-container" */).then(c => c.default || c),
      LazyErrorGeneric: () => import('../components/error/Generic.vue' /* webpackChunkName: "components/error-generic" */).then(c => c.default || c),
      LazyErrorMessage: () => import('../components/error/Message.vue' /* webpackChunkName: "components/error-message" */).then(c => c.default || c),
      LazyErrorStatusCode: () => import('../components/error/StatusCode.vue' /* webpackChunkName: "components/error-status-code" */).then(c => c.default || c),
      LazyIconsIconArrowUp: () => import('../components/icons/IconArrowUp.vue' /* webpackChunkName: "components/icons-icon-arrow-up" */).then(c => c.default || c),
      LazyIconsIconAtSymbol: () => import('../components/icons/IconAtSymbol.vue' /* webpackChunkName: "components/icons-icon-at-symbol" */).then(c => c.default || c),
      LazyIconsIconBadgeCheck: () => import('../components/icons/IconBadgeCheck.vue' /* webpackChunkName: "components/icons-icon-badge-check" */).then(c => c.default || c),
      LazyIconsIconBase: () => import('../components/icons/IconBase.vue' /* webpackChunkName: "components/icons-icon-base" */).then(c => c.default || c),
      LazyIconsIconBook: () => import('../components/icons/IconBook.vue' /* webpackChunkName: "components/icons-icon-book" */).then(c => c.default || c),
      LazyIconsIconCalendar: () => import('../components/icons/IconCalendar.vue' /* webpackChunkName: "components/icons-icon-calendar" */).then(c => c.default || c),
      LazyIconsIconClose: () => import('../components/icons/IconClose.vue' /* webpackChunkName: "components/icons-icon-close" */).then(c => c.default || c),
      LazyIconsIconDarkMode: () => import('../components/icons/IconDarkMode.vue' /* webpackChunkName: "components/icons-icon-dark-mode" */).then(c => c.default || c),
      LazyIconsIconDivider: () => import('../components/icons/IconDivider.vue' /* webpackChunkName: "components/icons-icon-divider" */).then(c => c.default || c),
      LazyIconsIconFacebook: () => import('../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icons-icon-facebook" */).then(c => c.default || c),
      LazyIconsIconFire: () => import('../components/icons/IconFire.vue' /* webpackChunkName: "components/icons-icon-fire" */).then(c => c.default || c),
      LazyIconsIconHappyRacoon: () => import('../components/icons/IconHappyRacoon.vue' /* webpackChunkName: "components/icons-icon-happy-racoon" */).then(c => c.default || c),
      LazyIconsIconHeart: () => import('../components/icons/IconHeart.vue' /* webpackChunkName: "components/icons-icon-heart" */).then(c => c.default || c),
      LazyIconsIconHome: () => import('../components/icons/IconHome.vue' /* webpackChunkName: "components/icons-icon-home" */).then(c => c.default || c),
      LazyIconsIconLightMode: () => import('../components/icons/IconLightMode.vue' /* webpackChunkName: "components/icons-icon-light-mode" */).then(c => c.default || c),
      LazyIconsIconLinkedIn: () => import('../components/icons/IconLinkedIn.vue' /* webpackChunkName: "components/icons-icon-linked-in" */).then(c => c.default || c),
      LazyIconsIconLogo: () => import('../components/icons/IconLogo.vue' /* webpackChunkName: "components/icons-icon-logo" */).then(c => c.default || c),
      LazyIconsIconLotus: () => import('../components/icons/IconLotus.vue' /* webpackChunkName: "components/icons-icon-lotus" */).then(c => c.default || c),
      LazyIconsIconMail: () => import('../components/icons/IconMail.vue' /* webpackChunkName: "components/icons-icon-mail" */).then(c => c.default || c),
      LazyIconsIconMenu: () => import('../components/icons/IconMenu.vue' /* webpackChunkName: "components/icons-icon-menu" */).then(c => c.default || c),
      LazyIconsIconNavInstagram: () => import('../components/icons/IconNavInstagram.vue' /* webpackChunkName: "components/icons-icon-nav-instagram" */).then(c => c.default || c),
      LazyIconsIconNavLinkedIn: () => import('../components/icons/IconNavLinkedIn.vue' /* webpackChunkName: "components/icons-icon-nav-linked-in" */).then(c => c.default || c),
      LazyIconsIconPhone: () => import('../components/icons/IconPhone.vue' /* webpackChunkName: "components/icons-icon-phone" */).then(c => c.default || c),
      LazyIconsIconQuestionMarkCircle: () => import('../components/icons/IconQuestionMarkCircle.vue' /* webpackChunkName: "components/icons-icon-question-mark-circle" */).then(c => c.default || c),
      LazyIconsIconRacoonWithShield: () => import('../components/icons/IconRacoonWithShield.vue' /* webpackChunkName: "components/icons-icon-racoon-with-shield" */).then(c => c.default || c),
      LazyIconsIconSadRacoon: () => import('../components/icons/IconSadRacoon.vue' /* webpackChunkName: "components/icons-icon-sad-racoon" */).then(c => c.default || c),
      LazyIconsIconScale: () => import('../components/icons/IconScale.vue' /* webpackChunkName: "components/icons-icon-scale" */).then(c => c.default || c),
      LazyIconsIconShare: () => import('../components/icons/IconShare.vue' /* webpackChunkName: "components/icons-icon-share" */).then(c => c.default || c),
      LazyIconsIconShieldExclamation: () => import('../components/icons/IconShieldExclamation.vue' /* webpackChunkName: "components/icons-icon-shield-exclamation" */).then(c => c.default || c),
      LazyIconsIconTwitter: () => import('../components/icons/IconTwitter.vue' /* webpackChunkName: "components/icons-icon-twitter" */).then(c => c.default || c),
      LazyIconsIconVegan: () => import('../components/icons/IconVegan.vue' /* webpackChunkName: "components/icons-icon-vegan" */).then(c => c.default || c),
      LazyIconsIconWhatsApp: () => import('../components/icons/IconWhatsApp.vue' /* webpackChunkName: "components/icons-icon-whats-app" */).then(c => c.default || c),
      LazyIconsIconXing: () => import('../components/icons/IconXing.vue' /* webpackChunkName: "components/icons-icon-xing" */).then(c => c.default || c),
      LazyKudoCard: () => import('../components/kudo/Card.vue' /* webpackChunkName: "components/kudo-card" */).then(c => c.default || c),
      LazyKudoCardContainer: () => import('../components/kudo/CardContainer.vue' /* webpackChunkName: "components/kudo-card-container" */).then(c => c.default || c),
      LazyKudoImage: () => import('../components/kudo/Image.vue' /* webpackChunkName: "components/kudo-image" */).then(c => c.default || c),
      LazyKudoText: () => import('../components/kudo/Text.vue' /* webpackChunkName: "components/kudo-text" */).then(c => c.default || c),
      LazyKudoTextarea: () => import('../components/kudo/Textarea.vue' /* webpackChunkName: "components/kudo-textarea" */).then(c => c.default || c),
      LazyKudoTitle: () => import('../components/kudo/Title.vue' /* webpackChunkName: "components/kudo-title" */).then(c => c.default || c),
      LazyLayoutsDefault: () => import('../components/layouts/Default.vue' /* webpackChunkName: "components/layouts-default" */).then(c => c.default || c),
      LazyLayoutsWithoutIntro: () => import('../components/layouts/WithoutIntro.vue' /* webpackChunkName: "components/layouts-without-intro" */).then(c => c.default || c),
      LazyMenuInternalLink: () => import('../components/menu/InternalLink.vue' /* webpackChunkName: "components/menu-internal-link" */).then(c => c.default || c),
      LazyMenuPage: () => import('../components/menu/Page.vue' /* webpackChunkName: "components/menu-page" */).then(c => c.default || c),
      LazyQuestionableAnswerCard: () => import('../components/questionable/AnswerCard.vue' /* webpackChunkName: "components/questionable-answer-card" */).then(c => c.default || c),
      LazyQuestionableCardDeck: () => import('../components/questionable/CardDeck.vue' /* webpackChunkName: "components/questionable-card-deck" */).then(c => c.default || c),
      LazyQuestionableProgress: () => import('../components/questionable/Progress.vue' /* webpackChunkName: "components/questionable-progress" */).then(c => c.default || c),
      LazyQuestionableQuestionCard: () => import('../components/questionable/QuestionCard.vue' /* webpackChunkName: "components/questionable-question-card" */).then(c => c.default || c),
      LazySharedButton: () => import('../components/shared/Button.vue' /* webpackChunkName: "components/shared-button" */).then(c => c.default || c),
      LazySharedDivider: () => import('../components/shared/Divider.vue' /* webpackChunkName: "components/shared-divider" */).then(c => c.default || c),
      LazySharedHeader: () => import('../components/shared/Header.vue' /* webpackChunkName: "components/shared-header" */).then(c => c.default || c),
      LazySharedRichContent: () => import('../components/shared/RichContent.vue' /* webpackChunkName: "components/shared-rich-content" */).then(c => c.default || c),
      LazyKudoLayoutsHighfive: () => import('../components/kudo/layouts/Highfive.vue' /* webpackChunkName: "components/kudo-layouts-highfive" */).then(c => c.default || c),
      LazyKudoLayoutsSuperstar: () => import('../components/kudo/layouts/Superstar.vue' /* webpackChunkName: "components/kudo-layouts-superstar" */).then(c => c.default || c),
      LazyKudoLayoutsThanks: () => import('../components/kudo/layouts/Thanks.vue' /* webpackChunkName: "components/kudo-layouts-thanks" */).then(c => c.default || c),
      LazyKudoLayoutsThanksChristmas: () => import('../components/kudo/layouts/ThanksChristmas.vue' /* webpackChunkName: "components/kudo-layouts-thanks-christmas" */).then(c => c.default || c),
      LazyKudoLayoutsWow: () => import('../components/kudo/layouts/Wow.vue' /* webpackChunkName: "components/kudo-layouts-wow" */).then(c => c.default || c)
  }

  const prefixes = ["nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
