
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({})
export default class Icon extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly name!: string
}
