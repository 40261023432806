import Vue from 'vue'
import { ArticleBlocksDynamicZone } from '~/graphql/generated'

const _readingTime = require('reading-time-i18n');

const readingTime = {
  install(Vue) {
    Vue.prototype.$readingTime = (blocks: ArticleBlocksDynamicZone[]): string => {
      const MarkdownIt = require('markdown-it')
      const md = new MarkdownIt()
      const text = md.render(blocks.map((block: ArticleBlocksDynamicZone) => {
        switch (block.__typename) {
          case 'ComponentBlocksBanner':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksQuote':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksSection':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksTextWithPolaroids':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksTwoColumns':
            return (block.leftColumn) ? ((block.rightColumn) ? `${block.leftColumn.content} ${block.rightColumn.content}` : block.leftColumn.content) : ''
          default:
            return ''
        }
      }).join(' ')).replace(/(<([^>]+)>|&.+;)/gi, '')
      return _readingTime(text, {locale: 'de-DE'}).i18nText
    }
  },
}

Vue.use(readingTime)
