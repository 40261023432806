import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e8ad238a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _06b09eae = () => interopDefault(import('../pages/linktree.vue' /* webpackChunkName: "pages/linktree" */))
const _63fe66c0 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _d6896d44 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _3f02cf63 = () => interopDefault(import('../pages/danke/_hash.vue' /* webpackChunkName: "pages/danke/_hash" */))
const _9b847f38 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _e8ad238a,
    name: "blog"
  }, {
    path: "/linktree",
    component: _06b09eae,
    name: "linktree"
  }, {
    path: "/blog/category/:slug?",
    component: _63fe66c0,
    name: "blog-category-slug"
  }, {
    path: "/blog/:article",
    component: _d6896d44,
    name: "blog-article"
  }, {
    path: "/danke/:hash(.*)",
    component: _3f02cf63,
    name: "danke-hash"
  }, {
    path: "/:slug?",
    component: _9b847f38,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
