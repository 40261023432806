import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=df6181c8&"
import script from "./Icon.vue?vue&type=script&lang=ts&"
export * from "./Icon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/vercel/path0/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default})
