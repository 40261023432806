import ellipsize from 'ellipsize'
import Vue from 'vue'
import { ArticleBlocksDynamicZone } from '~/graphql/generated'


const renderExcerpt = {
  install(Vue) {
    Vue.prototype.$renderExcerpt = (blocks: ArticleBlocksDynamicZone[], length: number = 200): string => {
      const MarkdownIt = require('markdown-it')
      const md = new MarkdownIt()
      const text = md.render(blocks.map((block: ArticleBlocksDynamicZone) => {
        switch (block.__typename) {
          case 'ComponentBlocksBanner':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksQuote':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksSection':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksTextWithPolaroids':
            return (block.text) ? block.text.content : ''
          case 'ComponentBlocksTwoColumns':
            return (block.leftColumn) ? ((block.rightColumn) ? `${block.leftColumn.content} ${block.rightColumn.content}` : block.leftColumn.content) : ''
          default:
            return ''
        }
      }).join(' ')).replace(/(&nbsp;|<([^>]+)>)/ig, '')
      return ellipsize(text, length)
    }
  },
}

Vue.use(renderExcerpt)
