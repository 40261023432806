import CryptoJS from 'crypto-js'

export default ({
  app,
  $config,
}, inject) => {
  const secret = `${Buffer.from($config.secret, 'base64').toString('ascii')}`
  inject('cryptojs', {
    encrypt: (data: {}): string => CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString(),
    decrypt: (hash: string): {} => JSON.parse(CryptoJS.AES.decrypt(hash, secret).toString(CryptoJS.enc.Utf8)),
  })
}
