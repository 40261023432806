import {
  QuestionableObjectiveEntity,
  QuestionableQuestionEntity,
} from '~/graphql/generated'

export enum TransitionDirection {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

interface QuestionableState {
  questions: QuestionableQuestionEntity[]
  objectives: QuestionableObjectiveEntity[]
  currentQuestion: QuestionableQuestionEntity
  questionsForm: Record<string, string>
  transitionDirection: TransitionDirection | null
}

export const strict = false

export const state = (): QuestionableState => ({
  questions: [],
  objectives: [],
  currentQuestion: null,
  questionsForm: {},
  transitionDirection: null,
})

export const getters = {
  questions(state: QuestionableState) {
    return state.questions
  },
  transitionDirection(state: QuestionableState) {
    return state.transitionDirection
  },
  questionsForm(state: QuestionableState) {
    return state.questionsForm
  },
  currentQuestion(state: QuestionableState) {
    return state.currentQuestion
  },
  indexOfCurrentQuestion(state: QuestionableState) {
    return state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
  },
  hasPreviousQuestion(state: QuestionableState) {
    const currentQuestionIndex = state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
    return currentQuestionIndex > 0
  },
  hasNextQuestion(state: QuestionableState) {
    const currentQuestionIndex = state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
    return currentQuestionIndex < state.questions.length - 1
  },
  isLastQuestion(state: QuestionableState) {
    const currentQuestionIndex = state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
    return currentQuestionIndex === state.questions.length - 1
  },
}

export const mutations = {
  setQuestions(
    state: QuestionableState,
    questions: QuestionableState['questions'],
  ) {
    state.questions = questions
    state.objectives = getUniqueObjectives(questions)
    state.questionsForm = getQuestionsForm(questions)
  },
  setCurrentQuestion(
    state: QuestionableState,
    question: QuestionableState['currentQuestion'],
  ) {
    state.currentQuestion = question
  },
  setPreviousQuestion(state: QuestionableState) {
    const currentQuestionIndex = state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
    const previousQuestion = state.questions[currentQuestionIndex - 1]
    if (!previousQuestion) {
      return
    }
    state.currentQuestion = previousQuestion
  },
  setNextQuestion(state: QuestionableState) {
    const currentQuestionIndex = state.questions.findIndex(
      (question) => question.id === state.currentQuestion.id,
    )
    const nextQuestion = state.questions[currentQuestionIndex + 1]
    if (!nextQuestion) {
      return
    }
    state.currentQuestion = nextQuestion
  },
  updateQuestionForm(
    state: QuestionableState,
    {
      questionId,
      answerId,
    }: { questionId: string; answerId: string },
  ) {
    state.questionsForm[questionId] = answerId
  },
  goToQuestionById(
    state: QuestionableState,
    { questionId }: { questionId: string },
  ) {
    state.currentQuestion = state.questions.find(
      (question) => question.id === questionId,
    )
  },
  setTransitionDirection(
    state: QuestionableState,
    transitionDirection: TransitionDirection,
  ) {
    state.transitionDirection = transitionDirection
  },
}

const getUniqueObjectives = (questions: QuestionableQuestionEntity[]) => {
  const objectives: QuestionableObjectiveEntity[] = []
  questions.map((question) => question.attributes.answers.data.map((answer) => {
      if (objectives.includes(answer.attributes.objective.data)) {
        return
      }
      objectives.push(answer.attributes.objective.data)
    }),
  )
  return objectives
}

const getQuestionsForm = (questions: QuestionableQuestionEntity[]) => {
  const questionsForm = {}
  questions.map((question) => {
    questionsForm[question.id] = null
  })
  return questionsForm
}
