import Vue from 'vue'
import { UploadFileEntityResponse } from '~/graphql/generated'
import { ImageFormat } from '~/models/Image'


const getLargestImageFormat = {
  install() {
    Vue.prototype.$getLargestImageFormat = (image: UploadFileEntityResponse): ImageFormat => {
      if (image.data.attributes.formats.xlarge) {
        return image.data.attributes.formats.xlarge
      } else if (image.data.attributes.formats.large) {
        return image.data.attributes.formats.large
      } else if (image.data.attributes.formats.medium) {
        return image.data.attributes.formats.medium
      } else if (image.data.attributes.formats.small) {
        return image.data.attributes.formats.small
      } else if (image.data.attributes.formats.thumbnail) {
        return image.data.attributes.formats.thumbnail
      } else {
        return image.data.attributes.formats.xsmall
      }
    }
  },
}

Vue.use(getLargestImageFormat)
