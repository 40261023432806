import { render, staticRenderFns } from "./WithoutIntro.vue?vue&type=template&id=a97c953c&"
import script from "./WithoutIntro.vue?vue&type=script&lang=ts&"
export * from "./WithoutIntro.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/vercel/path0/components/Header.vue').default})
