import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionResult from '~/graphql/fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
})

export default ({ $config }) => {
  return {
    httpEndpoint: $config.vueAppGraphQlUrl,
    cache: new InMemoryCache({ fragmentMatcher }),
  }
}
