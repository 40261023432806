export const strict = false
export const state = () => ({
  progressBarScene: null,
  menuVisible: false,
  isSticky: false,
  theme: 'light',
  contactForm: {
    submitted: false,
    name: {
      value: null,
      errors: [],
    },
    email: {
      value: null,
      errors: [],
    },
    subject: {
      value: null,
      errors: [],
    },
    message: {
      value: null,
      errors: [],
    },
    privacyDisclaimerAccepted: {
      value: false,
      errors: [],
    },
    successfullySent: false,
    sentFailed: false,
  },
})

export const mutations = {
  setContactForm(state, contactForm) {
    state.contactForm = contactForm
  },
  setContactFormSubject(state, value) {
    state.contactForm.subject.value = value
  },
  setMenuVisible(state, menuVisible) {
    state.menuVisible = menuVisible
  },
  setIsSticky(state, isSticky) {
    state.isSticky = isSticky
  },
  setProgressBarScene(state, scene) {
    state.progressBarScene = scene
  },
  setTheme(state, value) {
    state.theme = value
  },
}
