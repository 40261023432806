
import { ErrorType } from '~/models/Error'

export default {
  props: ['error'],
  computed: {
    errorComponent() {
      let errorComponentName
      switch (this.error.message) {
        case ErrorType.CARD_GENERATION_ERROR:
          errorComponentName = 'CardGenerationError'
          break
        case ErrorType.CARD_NOT_FOUND:
          errorComponentName = 'CardNotFound'
          break
        default:
          errorComponentName = 'Generic'
      }
      return `Error${errorComponentName}`
    },
  },
  layout: 'error',
}
