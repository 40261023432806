import { withBase, withoutLeadingSlash } from 'ufo'

export const getImage = (src, {
  modifiers,
} = { modifiers: null }, {
  nuxtContext,
}) => {
  const breakpoint = modifiers?.breakpoint ?? ''
  if (!breakpoint) {
    return {
      url: withBase(src, nuxtContext.$config.strapiUrl),
    }
  }
  return {
    url: withBase(`${breakpoint}_${withoutLeadingSlash(src)}`, nuxtContext.$config.strapiUrl),
  }
}

export const validateDomains = true
