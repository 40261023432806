
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class LayoutsWithoutIntro extends Vue {
  async mounted() {
    const currentHash = window.location.hash
    setTimeout(() => {
      if (currentHash) {
        const element = document.querySelector(currentHash)
        if (element) {
          window.scrollTo({
            top: element.getBoundingClientRect().top - 25,
          })
        }
      }
    }, 1)
  }
}
